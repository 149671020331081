@font-face {
	font-family: 'mm2';
	src: url('//2ttf.com/webfont/IBP5kT9w80q/webfont.ttf') format('truetype');
}

.App {
  text-align: center;
}

.magic {
	font-family: hobeaux-rococeaux-sherman, sans-serif;

	font-weight: 400;

	font-style: normal;
}

.magic6 {
	font-family: jaf-domus, sans-serif;

	font-weight: 800;

	font-style: normal;
}

.magic4 {
	font-family: Etna;
}

.magic3 {
	font-family: bd-brick;
}

.magic2 {
	font-family: rig-solid-bold-halftone;
}

.magic7{
	font-family: de-walpergens-pica, sans-serif;

}

.magicsmall{
	font-family: hobeaux-rococeaux-sherman, sans-serif;

	font-weight: 200;

	font-style: normal;
}

.weird {
	font-family: hobeaux-rococeaux-background;
}

.weird2 {
	font-family: hobeaux-rococeaux;
}


.weird3 {
	font-family: elfreth;
	font-weight: 400px;

}

.weird4 {
	font-family: elfreth-light;
	font-weight: 200px;

}
.weird5 {
	font-family: shlop, sans-serif;
	font-weight: 400;
	font-style: normal;
	color: #FF12DA;

}

/* wallet style overrides */

.wallet-adapter-button-trigger {
    background-color: #00EAFF !important;
}

.btn50 .btn{
	height: 50px;
}
.wallet-adapter-button{
	font-family: hobeaux-rococeaux-background !important;
}

.wallet-adapter-modal-container h1{
	font-family: rig-solid-bold-halftone !important;
}

/* end wallet overrides */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
